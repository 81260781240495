import React, { useEffect, useRef, useState } from "react"
import { MainLayout } from "../components/mainLayout/mainLayout"
import { SEO } from "../components/seo/seo"
import { graphql, useStaticQuery } from "gatsby"
import "./index.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import "./youngling.scss"
import { useIntl } from "gatsby-plugin-react-intl"

const YounglingPage = () => {
    const [active, setActive] = useState(null)
    const [userFirstAction, setUserFirstAction] = useState(false)
    const [showIntro, setShowIntro] = useState(true)
    const [showButton, setShowButton] = useState(false)
    const ref = useRef()
    const nextStep = () => {
        if (active == steps.length) {
            window.location.replace(
                "https://github.com/ColbaTechnologies/the-youngling-path/projects/1"
            )
        } else {
            setShowButton(false)
            setActive(active + 1)
            setTimeout(() => {
                setShowButton(true)
            }, 4000)
        }
    }

    useEffect(() => {
        let isMounted = true
        if (!showIntro) {
            setTimeout(() => {
                if (!isMounted) return
                setShowButton(true)
            }, 4000)
        }
        return () => {
            isMounted = false
        }
    }, [showIntro])

    useEffect(() => {
        if (userFirstAction) {
            let isMounted = true
            document.body.style.overflow = "hidden"
            setTimeout(() => {
                if (!isMounted) return
                if (ref.current) {
                    ref.current.play()
                }
            }, 6500)
            setTimeout(() => {
                if (!isMounted) return
                setActive(0)
                setShowIntro(false)
                document.body.style.overflow = "auto"
            }, 50000)
            return () => {
                isMounted = false
            }
        }
    }, [userFirstAction])
    const data = useStaticQuery(graphql`
        fragment carrerImage on File {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }
        query {
            youngling: file(relativePath: { eq: "youngling.png" }) {
                ...carrerImage
            }
            planet: file(relativePath: { eq: "planet.png" }) {
                ...carrerImage
            }
            starWarsLogo: file(relativePath: { eq: "starWarsLogo.png" }) {
                ...carrerImage
            }
        }
    `)

    const steps = [
        {
            title: "Hello youngling,",
            content: (
                <>
                    <p>
                        You are in "Inner Rim" the original edge of the Galactic
                        Republic, where expansion waves stopped for many
                        generations, now is the place were some brave younglings
                        start their journey
                    </p>
                    <p>
                        You have different paths to chose, maybe you are use to
                        play with the "Dark Side" of the servers, or maybe you
                        are more use to deal with the "Force" of the UI.
                    </p>
                </>
            ),
        },
        {
            title: "The real knowledge",
            content: (
                <p>
                    It doesn't matter,we encourage you to at least{" "}
                    <b style={{ textDecoration: "underline" }}>
                        the two first steps for each path.
                    </b>
                    We know you are a Youngling so listen to the Jedis, this is
                    our first advice "To achieve the Force you have to want to
                    find it" and is really good to have at least an introduction
                    to all the specializations, to help you to decide and also
                    have technical conversations with all the rest of roles on
                    the team.
                </p>
            ),
        },
        {
            title: "The paths",
            content: (
                <div>
                    <p>
                        To control the FORCE, you have to know each side of it
                        and be a JEDI on one of them
                    </p>
                    <ul>
                        <li>The Mobile Path</li>
                        <li>The Frontend Path</li>
                        <li>The Backend Path</li>
                        <li>The DevOps Path</li>
                    </ul>
                </div>
            ),
        },
        {
            title: "The Ajan Kloss Iterations",
            content: (
                <p>
                    Ajan Kloss is the Inner Rim moon, we call each moon period
                    "Iterations", each Iteration will present to you different
                    targets to achieve. Due to the orbit of our Moon each
                    iteration has different durations, here you have a guide of
                    each iteration
                </p>
            ),
        },
        {
            title: "Iteration I",
            content: (
                <p>
                    Is the quickest iteration, when Ajan Kloss is more green
                    than ever. These time you will learn some concepts and
                    technologies that all the paths needs to work. Also you will
                    meet the other younglings of your team and part of the
                    Jedi's Commision
                </p>
            ),
        },
        {
            title: "Iteration II",
            content: (
                <p>
                    After few days walking around Inner Rim, talking with your
                    new friends starts the most technical part of the trip,
                    where the limits of each youngling are over the table,
                    trying to understand all the concepts and new information
                    that will help you to achieve the FORCE. A lot of youngligns
                    abandon on this iteration, we hope you are ready to increase
                    your knowledge and show your potential
                </p>
            ),
        },
        {
            title: "Iteration III",
            content: (
                <p>
                    On Inner Rim things are different from your planet, here
                    after the Storm comes the calm, this small iteration is the
                    transition from the harder iteration to the most important
                    one. Take your decision, what is your path?
                </p>
            ),
        },
        {
            title: "Iteration IV",
            content: (
                <div>
                    <p>
                        The last, the hardest and the funniest Iteration of the
                        path, where all the younglings are together trying to
                        achive a project, this is why you want to be a Jedi. Be
                        able to code creativity each day of the rest of your
                        life
                    </p>
                </div>
            ),
        },
    ]

    return (
        <MainLayout darkMode={true}>
            <SEO title="Youngling" />
            <div className="main-content">
                {!userFirstAction ? (
                    <>
                        <h1>The Youngling Path</h1>
                        <div className="buttonContainer">
                            <button onClick={() => setUserFirstAction(true)}>
                                Start
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        {!showIntro && (
                            <h1>
                                {active != steps.length
                                    ? "The Youngling Path"
                                    : "May the FORCE be with you"}
                            </h1>
                        )}
                        {showIntro && (
                            <>
                                <section className="logo">
                                    <GatsbyImage
                                        className="image1"
                                        width={100}
                                        alt={"colba-logo"}
                                        image={
                                            data["starWarsLogo"].childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                </section>
                                <section className="intro">
                                    Hello Youngling!!!
                                </section>
                                <div id="board">
                                    <div id="content">
                                        <p id="title">Episode I</p>
                                        <p id="subtitle">THE YOUNGLING PATH</p>
                                        <br />
                                        <p>
                                            Each time that Yavin 4 gets aligned
                                            with Waaska, the "Youngling Path" is
                                            open, some Younglings arrive to
                                            Colba's office to find their place
                                            in the cloud
                                            <br />
                                            <br /> <br /> In some cases they are
                                            made to manage the "Force" of UI an
                                            others can be attracted by the "Dark
                                            Side" of the servers, and sometimes,
                                            some of them along the Youngling
                                            Path find the place that they are
                                            trying to find.
                                            <br />
                                            <br /> This is the story of the
                                            begining of another Youngling.
                                            <br />
                                            <br />
                                            <br /> Will you find your own path?
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}
                        {steps.map((item, index) => (
                            <div
                                key={index}
                                className={`step ${
                                    active == index ? "active" : "hidden"
                                }`}
                            >
                                <div className="contentStep">
                                    <h2>{item.title}</h2>
                                    {item.content}
                                </div>
                            </div>
                        ))}
                        <div className="buttonContainer">
                            {showButton && !showIntro && (
                                <button onClick={() => nextStep()}>
                                    {active == steps.length
                                        ? "Start"
                                        : "Continue"}
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
            <audio ref={ref} id="audio-player" autoPlay>
                <source
                    src="https://www.thesoundarchive.com/starwars/star-wars-theme-song.mp3"
                    type="audio/mpeg"
                />
                Your browser does not support the audio element.
            </audio>
        </MainLayout>
    )
}

export default YounglingPage
